import { RouteConfig } from 'vue-router';
import { InstructorDashboard } from './types';

const routes: RouteConfig[] = [
  {
    path: `/${InstructorDashboard.Pages.DASHBOARD}`,
    name: InstructorDashboard.Pages.INSTRUCTOR_DASHBOARD,
    component: () => import(/* webpackChunkName: "instructor-dashboard" */ './views/InstructorDashboard.vue'),
    meta: {
      hide_sidebar: true,
      layout: 'monitor',
    },
  },
];

export default routes;
