
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class AllReservationsTable extends Vue {
  dialog = false

  headers = [
    {
      text: '№ полета',
      value: 'fly_namber',
      sortable: false,
    },
    {
      text: '№ сессии',
      value: 'session_namber',
      sortable: false,
    },
    {
      text: 'time',
      value: 'time',
      sortable: false,
    },
    {
      text: 'ФИО',
      value: 'name',
      sortable: false,
    },
    {
      text: 'file',
      value: 'file',
      sortable: false,
    },
  ]

  @Prop({ required: true })
  tickets !: any[]
}
