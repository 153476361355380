
import { Component, Vue } from 'vue-property-decorator';
import { Checkbox } from '@/components';
import TicketsIssuedTable from '../components/TicketsIssuedTable.vue';
import AllReservationsTable from '../components/AllReservationsTable.vue';
import CompletedFlightsTable from '../components/CompletedFlightsTable.vue';

@Component({
  components: {
    Checkbox,
    TicketsIssuedTable,
    AllReservationsTable,
    CompletedFlightsTable,
  },
})
export default class InstructorDashboard extends Vue {
  currentTab = 0;
  dialog = false;

  tabs = [
    { title: 'Оформленные билеты', content: 'Контент для оформленных билетов' },
    { title: 'Все брони', content: 'Контент для всех броней' },
    { title: 'Завершенные полеты', content: 'Контент для завершенных полетов' },
  ];

  /* Генерация таблицы прыжков */
  generate_table_items(count: number) {
    const table_items = [];
    const names = [
      'Иванов Иван Иванович', 'Сидоров Дмитрий Иванович', 'Петров Алексей Сергеевич',
    ];
    const start_time = 9 * 60; // Начальное время в минутах (09:00)

    for (let i = 0; i < count; i++) {
      const name = names[i % names.length];
      const fly_namber = `D-${i + 1}`;
      const sessionNumber = (1111 + i).toString();
      const hours = Math.floor((start_time + i * 30) / 60);
      const minutes = (start_time + i * 30) % 60;
      const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`; // Формат времени
      const weight = Math.floor(Math.random() * (100 - 55 + 1) + 55);

      table_items.push({
        name,
        fly_namber,
        session_namber: sessionNumber,
        time,
        weight,
      });
    }

    return table_items;
  }

  tickets = this.generate_table_items(30);

  open_dialog() {
    this.dialog = true;
  }
}
