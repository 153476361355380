
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  PageTitle,
  Slider,
  Table,
  Avatar,
} from '@/components';
import {
  TickestTypesTranslate, TicketsStatuses, TicketsStatusesTranslate, OrderTypes,
} from '@/enums';
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { Tickets } from '@/modules/tickets/types';
import { statistics_store, global_store } from '@/store';
import printerIcon from '@/assets/icons/printer.svg?inline';
import { format_date } from '@/utils';
import AttractionStatisticCard from './AttractionStatisticCard.vue';
import { Statistics } from '../types';

  @Component({
    components: {
      printerIcon,
      PageTitle,
      Slider,
      AttractionStatisticCard,
      Table,
      Avatar,
    },
  })
export default class StatisticsDetail extends Vue {
  @Prop({ type: Array })
  history!: Tickets.Ticket[];

  @Prop({ type: Array, default: () => ([]) })
  creators!: Tickets.Creator[];

  @Prop({ type: Object })
  total!: Statistics.History['total'];

  expanded = [];
  print_data!:Statistics.StatisticsSortQuery

  get formattedDate() {
    return this.history?.[0]?.booked_at ? format_date(this.history[0].booked_at) : '';
  }

  get date() {
    return global_store.state.date;
  }

  comments_table_header = [
    {
      text: '',
      value: 'created_at',
    },
    {
      text: '',
      value: 'commentator',
    },
    {
      text: '',
      value: 'comment',
      width: '60%',
    },
  ];

  headers = [
    {
      text: 'Кассир',
      value: 'order.creator.name',
    },
    {
      text: 'Тип',
      value: 'order.type',
    },
    {
      text: '№ заказа',
      value: 'bitrix_order_id',
    },
    {
      text: 'Название',
      value: 'title',
    },
    {
      text: 'Медиапакет',
      value: 'media',
    },
    {
      text: 'Цена (₽)',
      value: 'price',
    },
    // {
    //   text: 'Стоимость билета (₽)',
    //   value: 'order.amount',
    // },
    {
      text: 'Время слота',
      value: 'timeslot.start_time',
    },
    {
      text: 'Владелец',
      value: 'order.buyer.surname',
    },
    {
      text: 'Статус',
      value: 'status',
    },
    {
      value: 'data-table-expand',
    },
  ];

  get_attributes_by_status(status: TicketsStatuses) {
    const attrs: Record<string, boolean|string|undefined> = {};

    if (status === TicketsStatuses.ACTIVE || status === TicketsStatuses.BOOKING) {
      attrs.outlined = true;
    }

    if (status === TicketsStatuses.CANCELED) {
      attrs.color = 'transparent';
    }

    if (status === TicketsStatuses.COMPLETED) {
      attrs.disabled = true;
    }

    return attrs;
  }

  get amountSum() {
    return this.total ? this.total.amountSum : 0;
  }

  get priceSum() {
    return this.total ? this.total.priceSum : 0;
  }

  receive_history_data(data:Statistics.StatisticsSortQuery) {
    this.print_data = data;
  }

  async printHistory() {
    const default_filters: Statistics.StatisticsSortQuery = {
      worktableId: 0,
      date: '',
      sort: '',
      sort_type: '',
      filter: '{}',
    };

    const filters_with_comments_ids = this.print_data ?? default_filters;

    const print_data_obj = JSON.parse(filters_with_comments_ids.filter);

    print_data_obj.is_show_comments_ids = this.is_show_comments_ids;
    filters_with_comments_ids.filter = JSON.stringify(print_data_obj);

    try {
      if (this.print_data) {
        await statistics_store.actions.get_printed_history(filters_with_comments_ids);
      } else {
        const data = {
          worktableId: Number(this.$route.params.id),
          date: this.date,
          filter: filters_with_comments_ids.filter,
        };

        await statistics_store.actions.get_printed_history(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  bayer_name(bayer: ClientsSharedType) {
    const { surname, firstname, middlename } = bayer;

    return `
      ${surname}
      ${firstname[0]}
      ${middlename ? middlename[0] : ''}`;
  }

  ticket_type_translate(val: keyof typeof TickestTypesTranslate) {
    return TickestTypesTranslate[val];
  }

  ticket_status_translate(val: keyof typeof TicketsStatusesTranslate) {
    return TicketsStatusesTranslate[val];
  }

  get_title(item: Tickets.Ticket) {
    if (item.order?.type === OrderTypes.OFFLINE) {
      return item.attraction?.title;
    } if (item.order?.type === OrderTypes.ONLINE) {
      return item.title;
    }
    return '';
  }

  is_show_comments_ids: number[] = [];

  on_row_expanded(items: Tickets.Ticket[]) {
    this.is_show_comments_ids = items.map((item) => item.id).filter((id) => id !== undefined);
  }
}
