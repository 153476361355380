
import {
  Component, Vue, Emit, Prop,
} from 'vue-property-decorator';
import filterIcon from '@/assets/icons/filterIcon.svg?inline';
import sortingIcon from '@/assets/icons/sortingIcon.svg?inline';
import { TicketsStatuses } from '@/enums/statuses/tickets';
import { Statistics } from '@/modules/statistics/types/index';
import { statistics_store, global_store, instructors_store } from '@/store';
import { TicketsStatusesTranslate, OrderTypesTranslate, OrderTypes } from '@/enums';
import { Tickets } from '@/modules/tickets/types/index';
import Select from '@/components/global/inputs/select/Select.vue';

const orderStatuses = TicketsStatuses;
const orderTypes = OrderTypes;

@Component({
  components: {
    filterIcon,
    sortingIcon,
    Select,
  },
})
export default class Table extends Vue {
  @Prop({ type: Boolean, default: false })
  hide_custom_header!: boolean

  @Prop({ type: Array, default: () => ([]) })
  creators!: Tickets.Creator[];

  selectedFilter = -1;
  sortingType = 'asc';
  showStatuses = false;
  showTypes = false;
  creatorsList = false;
  typeFilterValue = ''
  statusFilterValue = ''
  currentFilterId : number[] = [];
  selectedCreators = [] as unknown as Statistics.Creators[];
  currentSort !: string
  orderType : OrderTypes = OrderTypes.OFFLINE

  filterableValues = [
    {
      text: 'Кассир',
      value: 'order.creator.name',
    },
    {
      text: 'Тип',
      value: 'order.type',
    },
    {
      text: 'Статус',
      value: 'status',
    },
  ]

  @Emit()
  pass_history_data(data:Statistics.StatisticsSortQuery) {
    return data;
  }

  ticket_status_translate(val: keyof typeof TicketsStatusesTranslate) {
    return TicketsStatusesTranslate[val];
  }

  ticket_type_translate(val: keyof typeof OrderTypesTranslate) {
    return OrderTypesTranslate[val];
  }

  handleFilterIcon(head:Statistics.Header, index:number) {
    this.selectedFilter = index;

    if (head.value === 'status') {
      this.creatorsList = false;
      this.showStatuses = !this.showStatuses;
    } else if (head.value === 'order.type') {
      this.showTypes = !this.showTypes;
      this.creatorsList = false;
      this.showStatuses = false;
    } else if (head.value === 'order.creator.name') {
      this.creatorsList = !this.creatorsList;
    }
  }

  shouldRenderFilter(value: string): boolean {
    return this.filterableValues.some((item) => item.value === value);
  }

  get isStatusesExpanded() {
    return this.showStatuses;
  }

  get isTypesExpanded() {
    return this.showTypes;
  }

  get isCreatorsExpanded() {
    return this.creatorsList;
  }

  get statuses() {
    return Object.fromEntries(
      Object.entries(TicketsStatuses).filter(([key]) => key !== 'BOOKING'),
    );
  }

  get types() {
    return orderTypes;
  }

  get isStatisticsRoute(): boolean {
    return this.$route.path.includes('statistics');
  }

  get date() {
    return global_store.state.date;
  }

  async get_statistics() {
    try {
      await statistics_store.actions.get_statistics({
        worktableId: Number(this.$route.params.id),
        date: this.date,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async get_instructors() {
    try {
      await instructors_store.actions.get_instructors({
        worktableId: Number(this.$route.params.id),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getFilteredId(value: string) {
    this.currentFilterId = [];
    this.currentFilterId = this.selectedCreators.map((e) => e.id);

    const data = {
      worktableId: Number(this.$route.params.id),
      date: this.date,
      filter: `{"${value}":[${this.currentFilterId}]}`,
      sort: this.currentSort,
      sort_type: this.sortingType,
    };

    data.filter = '';
    const creatorNamesFilter = this.currentFilterId.length > 0
      ? `"order.creator.name":[${this.currentFilterId}]` : '';

    const selectedFilterType = this.typeFilterValue !== ''
      ? `"order.type":"${this.typeFilterValue}"` : '';

    const selectedStatus = this.statusFilterValue !== ''
      ? `"status":"${this.statusFilterValue}"` : '';

    const filters = [
      creatorNamesFilter,
      selectedFilterType,
      selectedStatus,
    ];

    data.filter = `{${filters.filter(Boolean).join(',')}}`;

    try {
      this.$emit('pass_history_data', data);

      await statistics_store.actions.get_filtered_history(data);
    } catch (e) {
      console.log(e);
    }
  }

  async getFilteredHistory(value: string, item : string) {
    const data = {
      worktableId: Number(this.$route.params.id),
      date: this.date,
      sort: this.currentSort,
      sort_type: this.sortingType,
      filter: `{"${value}":"${item}"}`,
    };

    if (value === 'status') {
      this.statusFilterValue = item;
    }
    if (value === 'order.type') {
      if (item !== orderTypes.ALL) {
        this.typeFilterValue = item;
        data.filter = `{"order.type":"${this.typeFilterValue}"`;
      } else {
        this.typeFilterValue = '';
      }
    }

    data.filter = '';
    const creatorNamesFilter = this.currentFilterId.length > 0
      ? `"order.creator.name":[${this.currentFilterId}]` : '';

    const selectedFilterType = this.typeFilterValue !== ''
      ? `"order.type":"${this.typeFilterValue}"` : '';

    const selectedStatus = this.statusFilterValue !== ''
      ? `"status":"${this.statusFilterValue}"` : '';

    const filters = [
      creatorNamesFilter,
      selectedFilterType,
      selectedStatus,
    ];

    data.filter = `{${filters.filter(Boolean).join(',')}}`;

    try {
      this.$emit('pass_history_data', data);
      await statistics_store.actions.get_filtered_history(data);
      this.showStatuses = false;
      this.showTypes = false;
    } catch (e) {
      console.log(e);
    }
  }

  async getSortedHistory(value: string) {
    this.creatorsList = false;
    this.currentSort = value;

    if (this.sortingType === 'asc') {
      this.sortingType = 'desc';
    } else {
      this.sortingType = 'asc';
    }
    try {
      const data = {
        worktableId: Number(this.$route.params.id),
        date: this.date,
        sort: value,
        sort_type: this.sortingType,
        filter: '',
      };

      data.filter = '';
      const creatorNamesFilter = this.currentFilterId.length > 0
        ? `"order.creator.name":[${this.currentFilterId}]` : '';

      const selectedFilterType = this.typeFilterValue !== ''
        ? `"order.type":"${this.typeFilterValue}"` : '';

      const selectedStatus = this.statusFilterValue !== ''
        ? `"status":"${this.statusFilterValue}"` : '';

      const filters = [
        creatorNamesFilter,
        selectedFilterType,
        selectedStatus,
      ];

      data.filter = `{${filters.filter(Boolean).join(',')}}`;

      this.$emit('pass_history_data', data);
      await statistics_store.actions.get_filtered_history(data);
    } catch (e) {
      console.log(e);
    }
  }
}
