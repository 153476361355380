
import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class TicketsIssuedTable extends Vue {
  dialog = false

  headers = [
    {
      // text: 'up',
      value: 'up',
      align: 'start',
      sortable: false,
    },
    {
      text: '№ полета',
      value: 'fly_namber',
      sortable: false,
    },
    {
      text: '№ сессии',
      value: 'session_namber',
      sortable: false,
    },
    {
      // text: 'down',
      value: 'down',
      sortable: false,
    },
    {
      text: 'time',
      value: 'time',
      sortable: false,
    },
    {
      text: 'ФИО',
      value: 'name',
      sortable: false,
    },
    {
      text: 'file',
      value: 'file',
      sortable: false,
    },
    {
      // text: 'start',
      value: 'start',
      sortable: false,
    },
    {
      // text: 'close',
      value: 'close',
      sortable: false,
    },
  ]

  @Prop({ required: true })
  tickets !: any[]

  @Emit()
  open_dialog() {
    return true;
  }

  up_time(item: any) {
    console.log(item);
  }
}
