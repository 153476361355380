
import { Component, Mixins } from 'vue-property-decorator';
import Logo from '@/assets/icons/logo.svg?inline';
import { PolicyMixin } from '@/mixins';
import { TextField } from '@/components';
import { global_store } from '@/store';
import HeaderSearch from '@/components/header/HeaderSearch.vue';

@Component({
  components: {
    Logo,
    TextField,
    HeaderSearch,
  },
})
export default class HeaderDashboard extends Mixins(PolicyMixin) {
  get current_time() {
    return global_store.state.time;
  }

  set current_time(val: string) {
    this.current_time = val;
  }

  get date() {
    return global_store.state.date;
  }

  set date(date) {
    global_store.mutations.SET_DATE(date);
  }

  /** ***********  ✨ Codeium Command ⭐  ************ */
  /**
   * Handles date change from datepicker and updates
   * the store with selected date.
   * @param {string} date - Selected date.
   */
  /** ****  25102293-60e0-4e2b-9fd3-737dcf6adc87  ****** */
  on_date_change(date: string) {
    this.date = date;
  }
}
