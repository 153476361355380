import { RouteConfig } from 'vue-router';
import { PolicyMixin } from '@/mixins/index';
import router from '@/router';
import { worktables_store } from '@/store';
import { Worktables } from './types';

const { state, actions } = worktables_store;

const policy = new PolicyMixin();

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Worktables.Pages.WORKTABLES,
    component: () => import(/* webpackChunkName: "worktables" */ './views/WorktablesLayout.vue'),

    async beforeEnter(to, from, next) {
      if (!state.worktables.length) {
        await actions.get_worktables();
      }
      const first_worktable_id = state.worktables[0]?.id?.toString();

      if (first_worktable_id && to.params.id !== first_worktable_id) {
        return next(`/${Worktables.Pages.WORKTABLES}/${first_worktable_id}`);
      }
      next();
    },

    children: [
      {
        path: `/${Worktables.Pages.WORKTABLES}/:id`,
        name: Worktables.Pages.WORKTABLE_DETAIL,
        component: () => import(/* webpackChunkName: "worktable-detail" */ './views/WorktableDetail.vue'),
      },
    ],
  },
  {
    path: `/${Worktables.Pages.WORKTABLES}`,
    name: Worktables.Pages.WORKTABLES_TABLE,
    component: () => import(/* webpackChunkName: "worktables-table" */ './views/WorktablesTable.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(to, from, next) {
      const access = policy.check_permissions(policy.permissions.WorktablePage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
];

export default routes;
