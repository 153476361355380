
import { Component, Vue } from 'vue-property-decorator';
import HeaderDashboard from '@/modules/instructor-dashboard/components/HeaderDashboard.vue';
import { Layouts } from './types';

@Component({
  name: Layouts.DEFAULT,
  components: {
    HeaderDashboard,
  },
})
export default class AuthLayout extends Vue {
}
