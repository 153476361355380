
import { Component, Mixins } from 'vue-property-decorator';
import { worktables_store } from '@/store';
import ClientSharedCard from '@/modules/clients/shared/components/ClientCardShared.vue';
import { ErrorHandlereMixin } from '@/mixins';
import OrderSharedCard from '@/modules/orders/shared/components/OrderSharedCard.vue';
import AddWorktableDialog from '../components/AddWorktableDialog.vue';
import WorktablesTabs from '../components/WorktablesTabs.vue';
import { Worktables } from '../types';
import WorktableDetailOptions from '../components/WorktableDetailOptions.vue';

const { state, actions } = worktables_store;

@Component({
  components: {
    WorktablesTabs,
    AddWorktableDialog,
    WorktableDetailOptions,
    ClientSharedCard,
    OrderSharedCard,
  },
})
export default class WorktablesView extends Mixins(ErrorHandlereMixin) {
  add_worktable_dialog_model = false;
  on_create_error = false;

  snackbar_demo = true;

  get worktables() {
    return state.worktables;
  }

  get current_worktable() {
    return state.current_worktable;
  }

  add_worktable_dialog(val: boolean) {
    this.add_worktable_dialog_model = val;
  }

  async add_worktable(payload: Worktables.Worktable) {
    try {
      const idx = await actions.create_worktable(payload);

      this.add_worktable_dialog_model = false;
      this.$router.push({
        name: Worktables.Pages.WORKTABLE_DETAIL,
        params: {
          id: String(idx),
        },
      });
    } catch (error) {
      this.handleServerError(error);
      this.on_create_error = true;
    }
  }
}
